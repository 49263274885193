import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { OrderService } from 'app/shared/services/order.service';
import { MatSnackBar, MatSidenav, MatSelect, MatDialog, MatDialogRef } from '@angular/material';
import { MediaChange, ObservableMedia } from '@angular/flex-layout';
import { carparts } from 'app/shared/models/carparts.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { sparepartfromcorporate } from 'app/shared/models/sparepartfromcorporate.model';
import { stock } from 'app/shared/models/stock.model';
import { Subscription,Observable } from 'rxjs';
import { Router } from '@angular/router';
import { Globals } from 'app/globals';
import { map, startWith} from 'rxjs/operators'
import { CarimgpopupComponent } from './carimgpopup/carimgpopup.component';
import { PartimgpopupComponent } from './partimgpopup/partimgpopup.component';
import { AppLoaderService } from 'app/shared/services/app-loader/app-loader.service';
import { MakedialogueComponent } from '../vehicledetails/makedialogue/makedialogue.component';
import { ModeldialogueComponent } from '../vehicledetails/modeldialogue/modeldialogue.component';
import { PartsdialogueComponent } from '../vehicledetails/partsdialogue/partsdialogue.component';

@Component({
  selector: 'app-newvehicle',
  templateUrl: './newvehicle.component.html',
  styleUrls: ['./newvehicle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,

})
export class NewvehicleComponent implements OnInit {
  isMobile;
  hasvehicles: Boolean = false;
 screenSizeWatcher: Subscription;
 isSidenavOpen: Boolean = true;
 formData = {}
 carregno = " ";
 console = console;
 carsparesarray: sparepartfromcorporate = {car_parts:[]};
 allcarsparesarray =[];
 basicForm: FormGroup;
 carpartsData: carparts = {}; 
 stock: stock = {};
 subscription: any;
 selectedmake:string;
 selectedmodel:string;
 selectedyom:number[] = [];
 selectedpart:string;
 makes:any
 partnames:any
 yoms=["1980","1981","1982","1983","1984","1985","1986","1987","1988","1989","1990","1991","1992","1993","1994","1995","1996","1997","1998","1999","2000",
 "2001","2002","2003","2004","2005","2006","2007","2008","2009","2010","2011","2012","2013","2014","2015","2016","2017","2018","2019","2020","2021","2022","2023","2024","2025"]

 matSelectmake: MatSelect;

carimg:any =[]
partimg:any=[];
filteredOptions: Observable<string[]>;
models:any= [];

selectedYoms: number[] = [];

  
@ViewChild(MatSelect) matSelect: MatSelect; 
 @ViewChild(MatSidenav) private sideNav: MatSidenav;
 constructor(private media: ObservableMedia,
   private orderservice: OrderService ,private router: Router,
   private globals: Globals,
   private snackBar: MatSnackBar,
   private dialog: MatDialog, public http:HttpClient,private loader: AppLoaderService,private ref: ChangeDetectorRef) { 

 //  setTimeout(()=>{ this.hasvehicles = true }, 10000);
 }

 ngOnInit() {

  this.getItemsval();
  this.getItemsp();
   this.inboxSideNavInit();
   
   this.yoms=this.yoms.reverse();
   
   this.basicForm = new FormGroup({

     carreg_no: new FormControl('', 

       Validators.required
     ),


     part_name: new FormControl('', 
     Validators.required
   ) ,
   chassis_no: new FormControl('', 
  
 ) ,
     quantity: new FormControl('', 
       Validators.required
     ) ,
     part_number: new FormControl('', 
       
     ),
     jobcard_no: new FormControl('', 
       
     ), 
     description: new FormControl('', 
       
     ), 
     part_price: new FormControl('', 
     Validators.required
   ) ,
   })

this.makes=this.orderservice.getMakemodel();

this.partnames=this.orderservice.getPart();

this.search2();

 }

  clearpartsection(){
  
   // this.basicForm.controls['part_name'].reset();

    this.basicForm.controls['part_number'].reset();

    this.basicForm.controls['quantity'].reset();

    this.basicForm.controls['description'].reset();
 
      this.basicForm.get('part_name').setValue(null);
  }

     checkpartfield(){

  
     if( this.stock.partName){

       return false;

     }else{
       return true;
     }

        }


 addStock() {


 
   this.stock.car_make = this.selectedmake;
   this.stock.car_model = this.selectedmodel;
   this.stock.car_yom = this.selectedyom;  
  // this.carsparesarray.car_imgs = this.carimg;
   this.stock.chassis_no = this.basicForm.value.chassis_no;
  
   this.stock.partName = this.selectedpart;
   this.stock.partNumber = this.basicForm.value.part_number;
   this.stock.partQuantity = this.basicForm.value.quantity;
   this.stock.partDescription = this.basicForm.value.description;
   this.stock.partPrice = this.basicForm.value.part_price; 
 //  this.carpartsData.part_imgs=this.partimg;

  
 //this.selectedpart=null;
 //this.clearpartsection();
 //this.search2();
 //console.log(this.carsparesarray.car_parts.length+"jjjjjjjjj");
 }


 onSubmit(){

  console.log(this.stock.car_make+"--------===============-------jjjjjjjjjthis.selectedpart "+this.selectedpart);

  
  this.loader.open();

     if(this.stock.car_make){
    
      console.log("--------===============-------jjjjjjjjjthis.selectedpart2  22 ");

this.basicUpload(this.carimg,this.stock)

      /*
this.subscription = this.orderservice.postReiceivedVehicle(this.carsparesarray).subscribe(

     data => {
       // refresh the list
      // this.items =this.temp = data;
    //   console.log(JSON.stringify( data )+"-------------------");


   this.subscription.unsubscribe();

    this.carsparesarray.car_make = null;

    this.openSnackBar("Success")


    // this.clearObjectValues(this.carsparesarray);

   console.log(data+"----------------------=-===============-------jjjjjjjjj ");

     this.basicForm.reset();

      // return true;
     },
     error => {
       console.error("Error saving !");
     //  return Observable.throw(error);
    }

   );   
*/

 
    
  //   }
   }else{
   

   }
   
 }



 updateSidenav() {
   let self = this;
   setTimeout(() => {
     self.isSidenavOpen = !self.isMobile;

     self.sideNav.mode = self.isMobile ? 'over' : 'side';

   })
 }
 inboxSideNavInit() {
   this.isMobile = this.media.isActive('xs') || this.media.isActive('sm');
   this.updateSidenav();
   this.screenSizeWatcher = this.media.subscribe((change: MediaChange) => {
     this.isMobile = (change.mqAlias == 'xs') || (change.mqAlias == 'sm');
     this.updateSidenav();
   });
 }


 modelsgive(){

   if(this.makes && this.selectedmake){

  var itm = this.makes.find(x=>x.vehicle_make == this.selectedmake);

return itm.vehicle_model;


   }
   else{

   // this.getItemsval();
     return   ["Select Vechile Make"];



   }
   //..console.log("er5432345fcdcsert   ");
 }




 checkselect(){
   if(!this.selectedmake){
     return true;
   }else{
     return false;
   }
 }
 changeClientmake(data){

 //console.error("============================================ changeClient !" );

 this.selectedmake = data

 // this.matSelectmake = data.source;
  //..alert("selected --->"+data);
}

changeClientmodel(data){
  //  console.error("============================================ changeClient !"+data);

  this.selectedmodel=data;

  // this.selectedmod=data;
    //..alert("selected --->"+data);
  }

  changeClientyom(data){
    //  console.error("============================================ changeClient !"+data);
    this.selectedyom=data;
    // this.selectedmod=data;
      //..alert("selected --->"+data);
  
    }
  
    changeClientyomm(selectedValues: number[]): void {
     // this.selectedYoms = selectedValues;
      this.selectedyom = selectedValues;
     // console.log(this.selectedyom+"------------");
    }
  
    removeYom(yom: number): void { const index = this.selectedyom.indexOf(yom); 
      if (index >= 0) { this.selectedyom.splice(index, 1);
         this.matSelect.value = this.selectedyom; // Update the mat-select value
         } } 


    changeClientpart(data){
      //  console.error("============================================ changeClient !"+data);
     this.selectedpart=data;
      // this.selectedmod=data;
        //..alert("selected --->"+data);
      }



      getItemsval() {
  //this.items =this.temp =this.orderservice.getOrders2();
  var subv= this.orderservice.getallVehicles().subscribe(
    data => {
      subv.unsubscribe;
       // refresh the list

     //  this.itemsv =this.tempv = 
      // data;
      this.orderservice.setMakemodel(data);

      this.makes= data



     //.. this.modelsgive();
      //.. this.orderservice.setMake(data["vehicle_make"]);
      //.. this.orderservice.setModel(data["vehicle_model"]);
    //   this.itemcarmethod(data);
    //   console.log("-------------------"+JSON.stringify( data));
       
       return true;
     },
     error => {
       console.error("Error saving !");
     //  return Observable.throw(error);
    }
  );
   
   // console.log(  +"-------------------");

    }






 
    getItemsp() {
    
      //this.items =this.temp =this.orderservice.getOrders2();
      var sub=this.orderservice.getallPart().subscribe(
        data => {
          sub.unsubscribe()
           // refresh the list

           this.orderservice.setPart(data);
        
           return true;
         },
         error => {
           console.error("Error saving !");
         //  return Observable.throw(error);
        }
      );
       
       // console.log(  +"-------------------");
  
        }
  
  



        uploadCarpartimg(){

         
            const dialogRef = this.dialog.open( PartimgpopupComponent, {
              width: '50%',
              height: '50%'
            });  
            
            dialogRef.afterClosed().subscribe(
              data=>{

                console.log(data);

                for(data of data){

                  let url = data.url

                  let i = url.indexOf(',')+1
                  console.log(url.slice(i));
                  
            this.partimg.push(url.slice(i));


                }
              }
            )

        }


        uploadCarimg(){

          this.addStock();     
          const dialogRef = this.dialog.open( CarimgpopupComponent, {
            width: '50%',
            height: '50%'
          });  
          dialogRef.afterClosed().subscribe(
            data=>{

              console.log("Dialog close data",data);

              this.carimg = data

       /*       for(data of data){

                let url = data.url

                let i = url.indexOf(',')+1
                console.log(url.slice(i));
                
             this.carimg.push(url.slice(i));


              } */


            }
          )

      }



 ngOnDestroy(){
   /*if(this.subscription){
  this.subscription.unsubscribe();
   }*/
 }


 openSnackBar(text:string) {
  this.snackBar.open(text,'' ,{
    duration: 3000,panelClass: ['blue-snackbar'], verticalPosition: 'top', horizontalPosition: 'end'
  });
}




basicUpload(myFiles, data){
 
  let files: File[] = myFiles
  var formData = new FormData();

  Array.from(files).forEach(f => formData.append('file', f))

  console.log("FORM DATA IS......" , formData);

  formData.append('field',JSON.stringify(data))

  this.http.post(this.orderservice._url+'add/stock/parts', formData)

    .subscribe(event => {

      console.log('done');
      this.loader.close();
      this.router.navigate(['others']);
     
    })
}





syncMake(){


 /* console.log("MAKES 1......", this.makes);

  this.makes = [...this.makes, ...data]
  this.ref.markForCheck()

  console.log("MAKES 2......", this.makes);*/



  var subv= this.orderservice.getallVehicles().subscribe(
    data => {
      subv.unsubscribe;
  
      this.orderservice.setMakemodel(data);
  
      this.makes= data
  
      this.ref.markForCheck()
  
  
       return true;
     },
     error => {
       console.error("Error saving !");
    }
  );


}





openmakePopUp(data: any = {}, isNew?) {

  let title = isNew ? 'Add Vehicle Make' : 'Update Vehicle Make';

  let dialogRef: MatDialogRef<any> = this.dialog.open(MakedialogueComponent, {

    width: '720px',
    disableClose: true,
    data: { title: title, payload: data }
  })
  
  dialogRef.afterClosed()
    .subscribe(res => {

console.log("THE NEW MAKE IS...", res);



     if(!res) {

        // If user press cancel


     return;
    }else{

this.syncMake()

   }



     // this.getItemsp();
     // this.loader.open();
      if (isNew) {
    /*    this.crudService.addItem(res)
          .subscribe(data => {
            this.items = data;
            this.loader.close();
            this.snack.open('Member Added!', 'OK', { duration: 4000 })
          }) */
      } else {
      /*  this.crudService.updateItem(data._id, res)
          .subscribe(data => {
            this.items = data;
            this.loader.close();
            this.snack.open('Member Updated!', 'OK', { duration: 4000 })
          }) */
      }
    })
}


openmodelPopUp(data: any = {}, isNew?) {

 
  let title = isNew ? 'Add Vehicle Model' : 'Update Vehicle Model';
  let dialogRef: MatDialogRef<any> = this.dialog.open(ModeldialogueComponent, {
    width: '720px',
    disableClose: true,
    data: { title: title, payload: data }
  })
  dialogRef.afterClosed()
    .subscribe(res => {

      console.log("DATA RES", res);

      if(!res) {
        // If user press cancel


        return;
      }
      else{

  this.syncMake()

    }

    
     
      if (isNew) {
    /*    this.crudService.addItem(res)
          .subscribe(data => {
            this.items = data;
            this.loader.close();
            this.snack.open('Member Added!', 'OK', { duration: 4000 })
          }) */
      } else {
      /*  this.crudService.updateItem(data._id, res)
          .subscribe(data => {
            this.items = data;
            this.loader.close();
            this.snack.open('Member Updated!', 'OK', { duration: 4000 })
          }) */
      }
    })
}





syncParts(part){

  console.log("OPTIONS HAS BEEN CLICKED....");

  console.log("PARTS......", this.partnames);

  this.partnames = [...this.partnames, ...part]
  this.ref.markForCheck()

  console.log("PARTS......", this.partnames);


}

opencarpartPopUp(data: any = {}, isNew?) {

  
  let title = isNew ? 'Add Vehicle Part' : 'Update Vehicle Part';

  let dialogRef: MatDialogRef<any> = this.dialog.open(PartsdialogueComponent, {
    width: '720px',
    disableClose: true,
    data: { title: title, payload: data }
  })
  dialogRef.afterClosed()
    .subscribe(res => {

  
console.log("DATA RES", res);



     if(res.cancel) {
        // If user press cancel
  
        return;
      }else{

        this.syncParts(res)
    

      }


     // this.loader.open(); 
      if (isNew) {
    /*    this.crudService.addItem(res)
          .subscribe(data => {
            this.items = data;
            this.loader.close();
            this.snack.open('Member Added!', 'OK', { duration: 4000 })
          }) */
      } else {
      /*  this.crudService.updateItem(data._id, res)
          .subscribe(data => {
            this.items = data;
            this.loader.close();
            this.snack.open('Member Updated!', 'OK', { duration: 4000 })
          }) */
      }
    })
}


	
private _filter(value: string) {

  const filterValue = value.toLowerCase();



  return this.partnames.filter(option => option.part_name.toLowerCase().includes(filterValue));

/*
  return this.partnames.filter((search2: any) =>

  (search2.part_name +  ' ' .toString() ).toLowerCase().indexOf(filterValue) > -1)

*/


}		 
			  
			  
			  
search2(){

  this.filteredOptions = this.basicForm.controls["part_name"].valueChanges
  .pipe(
    startWith(''),
    map(value => this._filter(value))


  );
}

onDelete(name: string){


  this.removePart( this.carsparesarray.car_parts.find(x=> x.partName == name) );
}
removePart(doc){
  this.carsparesarray.car_parts.forEach( (item, index) => {
    if(item === doc) this.carsparesarray.car_parts.splice(index,1);
  });
}

}


