import { Component, OnInit,Inject, ElementRef,ViewChild } from '@angular/core';
//import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";
import { ActivatedRoute, Router } from '@angular/router';
import { OrderService } from 'app/shared/services/order.service';
import { delay } from 'rxjs-compat/operator/delay';
//import * as $ from 'jquery';



declare var $: any;

@Component({
  selector: 'app-printer',
  templateUrl: './printer.component.html',
  styleUrls: ['./printer.css']
})



export class PrinterComponent implements OnInit {


  @ViewChild('myPrint') print2:ElementRef


  myData:any=[]

  productID:any

  Order:any
  completed:any=[]

  myParts:any=[]
  total=0
  bids:any=[]
  personel:String=""
  company:String=""
  constructor(private router2:Router,private route: ActivatedRoute, private orderservice: OrderService) { 


    console.log("IN THE NEW WINDOW.....");

    
    this.productID = this.route.snapshot.params['id'];


  console.log("ID IS....",this.productID );





  this.orderservice.getOrders().subscribe(
    data => {
       // refresh the list
       this.Order = data;
       console.log(JSON.stringify( data )+"-------------------");


   this.Order= this.Order.find(x=>x.order_id == this.productID)



     console.log("ORDERS ARE", this.Order);


  
     this.findByName2("Complete", this.Order.autorised_parts).then((data)=>{
  
      let part_Length = 0
      let i = 0

  
      console.log("the complete are....", data);
     // console.log(data);
      
      this.completed = data



part_Length = data.length 


      data.map((d,f,g)=>{

        

    console.log("part...",d.part_id);




        
     this.orderservice.getPartBids(d.part_id).subscribe(
        
      (data2:any) => {


    



        console.log("Bids are...", data2);

        if(data2.find(x=>x.bidStatus[x.bidStatus.length-1] == "won confirmed")){

          let itemwon:any = data2.find(x=>x.bidStatus[x.bidStatus.length-1]== "won confirmed");
       
          console.log("WON BID......", itemwon.bidAmount);
          

          this.bids.push({

            partname:d.partName,
            supplier:itemwon.bidSourceName,
            supplier_num:itemwon.bidSupplierPhone,
            confirmed:"Confirmed",
            quantity:d.partQuantity,
            amount:itemwon.bidAmount,
    
            })


        }else{
      
      let unconfurmed:any = data2.find(x=>x.bidStatus[x.bidStatus.length-1]== "won unconfirmed");
      
      this.bids.push({

        partname:d.partName,
        supplier:unconfurmed.bidSourceName,
        supplier_num:unconfurmed.bidSupplierPhone,
        confirmed:"Unconfirmed",
        quantity:d.partQuantity,
        amount:unconfurmed.bidAmount,

        })


       }
  
     
     console.log("Number of partss.....",part_Length);
     

     console.log("Sub number....", i);
     

        console.log("Bids are...", this.bids);


        if(part_Length-1 == i){

   setTimeout(()=>{
    this.print2.nativeElement.click();
    console.log("we can print now.....");

   },3000)
    
        }
        i+=1


       }


    );


        
   
      })
    
    
    
    
    }).catch(err=>{
    
    
      console.log("error occured");
    
      this.completed=[]
    
    })







       return true;
     },

     error => {
       console.error("Error saving !");
     //  return Observable.throw(error);
    }
  );


  console.log("BIDS", this.bids);
  





    }

  ngOnInit() {

  this.personel = localStorage.getItem('uemail')
  this.company = localStorage.getItem('cnumber')





//PRINT CLICK EVENT


const that = this; 
$('#printInvoice').click(function(){


/*
  var divToPrint = document.getElementById('myStuf');

  var newWin = window.open('','Print-Window');

  newWin.document.open();

  newWin.document.write('<html><body onload="window.print()">' + divToPrint.innerHTML +  '</body></html>');

  newWin.document.close();

  setTimeout(()=>{

    newWin.close();

  },   10)   //wimdow.close()


*/



  Popup($('#myStuf')[0].outerHTML);

  function Popup(data)
  {
      window.print();
      console.log("printing done....");

      return true;

  }


  console.log("printing done 235....");

  window.close()

 // that.router2.navigate(['/orders']);

  

   //Hide all other elements other than printarea.
/*

   $("#myStuf").show();
   window.print();


*/



/*

$("#invoice").printThis({
  debug: true,               // show the iframe for debugging
  importCSS: true,            // import parent page css
  importStyle: true,         // import style tags
  printContainer: true,       // print outer container/$.selector
  loadCSS: "[]",   // path to additional css file - use an array [] for multiple
  pageTitle: "Print Complete Items",              // add title to print page
  removeInline: false,        // remove inline styles from print elements
  removeInlineSelector: "*",  // custom selectors to filter inline styles. removeInline must be true
  printDelay: 333,            // variable print delay
  header: null,               // prefix to html
  footer: null,               // postfix to html
  base: false,                // preserve the BASE tag or accept a string for the URL
  formValues: true,           // preserve input/form values
  canvas: false,              // copy canvas content
  doctypeString: '...',       // enter a different doctype for older markup
  removeScripts: false,       // remove script tags from print content
  copyTagClasses: false,      // copy classes from the html & body tag
  beforePrintEvent: null,     // function for printEvent in iframe
  beforePrint: null,          // function called before iframe is filled
  afterPrint: function (){
    console.log("done print....", this.router);

    
    this.router.navigate('/orders');

    
  },          // function called before iframe is removed
});

*/


/*
var divToPrint=document.getElementById("myStuf");
let newWin= window.open("");
newWin.document.write(divToPrint.outerHTML);
newWin.print();
newWin.close();

*/



/*
var divToPrint = document.getElementById('myStuf');

let htmlToPrint = divToPrint.outerHTML;

let newWin = window.open("");
//newWin.document.write("<h3 align='center'>Print Page</h3>");
newWin.document.write(htmlToPrint);
newWin.print();
newWin.close();


*/


});







  }

  findByName2(searchKey: string, orders:Array<any>) {

		let key: string = searchKey.toUpperCase();

		return Promise.resolve(
      
      orders.filter((order: any) =>

      (order.part_status +  ' ' ).toUpperCase().indexOf(key) > -1)
      
      
      );

		}

    printComponent(cmpName) {

      let printContents = document.getElementById(cmpName).innerHTML;
      let originalContents = document.body.innerHTML;
  
      document.body.innerHTML = printContents;
  
      window.print();
  
      document.body.innerHTML = originalContents;
  }



  donePrint(){

  //  this.router2.navigate(['/print',this.productID]);



  }


  totalAmount(){

let total = 0
    this.bids.forEach((item,index)=>{

      total += (item.amount * item.quantity)
      

      console.log("the total....", total );
      
      })

      return total
  }

}
