import { Component, OnInit, Inject } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";
import { map } from 'rxjs/operators';



@Component({
  selector: 'app-vehiclepics',
  templateUrl: './vehiclepics.component.html',
  styleUrls: ['./vehiclepics.component.scss']
})

export class VehiclepicsComponent implements OnInit {

  myData:any=[]

  constructor( private dialogRef: MatDialogRef<VehiclepicsComponent>,
    @Inject(MAT_DIALOG_DATA) data) { 

    //
  //this.myData = ["https://res.cloudinary.com/dw9pws08t/image/upload/v1562584054/ctymmdmdfbgbmp16nnuc.jpg","https://res.cloudinary.com/dw9pws08t/image/upload/v1562584056/i4ktac2ue45ckqxmfdbt.jpg","https://res.cloudinary.com/dw9pws08t/image/upload/v1562584057/zgnqcn1dfrhwvko5uzee.jpg"]
   // this.myData = data

   data.car_imgUrl.map((d,f,g)=>{

      console.log("d is..", d);
      console.log("f is..", f);
      console.log("g is..", g);

      this.myData= d

    })

   //  this.myData = data.car_imgUrl
      
    }

  ngOnInit() {



  }

close(){

  this.dialogRef.close()
}


}
