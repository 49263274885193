import { Routes } from "@angular/router";
import { NewvehicleComponent } from "./newvehicle/newvehicle.component";
import { VehicledetailsComponent } from "./vehicledetails/vehicledetails.component";





export const VehicleRoutes: Routes = [
    {
      path: '',
      //component: NewvehicleComponent,
     // data: { title: 'Blank', breadcrumb: 'BLANK' }
      children: [{
        path: 'newvehicle',
        component: NewvehicleComponent,
        data: { title: 'Blank', breadcrumb: 'BLANK' }
      }, 
      {
        path: 'vehicledetails',
          component: VehicledetailsComponent,
          data: { title: 'Basic', breadcrumb: 'BASIC' }
      }  
      /*, 
      {
        path: 'suppliers',
          component: SuppliersComponent,
          data: { title: 'Basic', breadcrumb: 'BASIC' }
      } , 
      {
        path: 'vehicles',
          component: VehiclesComponent,
          data: { title: 'Basic', breadcrumb: 'BASIC' }
      } */
    
    ]
    /*, 
    {
      path: 'showorderparts/:id',
        component: ShoworderpartsComponent,
        data: { title: 'Basic', breadcrumb: 'BASIC' }
    }  , 
    {
      path: 'particularpart/:id',
        component: ParticularpartComponent,
        data: { title: 'Basic', breadcrumb: 'BASIC' }
    }   
    */
  
  }
 
  ];