import { PrintLayoutComponent } from './shared/components/layouts/print-layout/print-layout.component';
import { PrinterComponent } from './views/others/orders/printer/printer.component';
import { Routes } from '@angular/router';
import { AdminLayoutComponent } from './shared/components/layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './shared/components/layouts/auth-layout/auth-layout.component';
import { AuthGuard } from './shared/services/auth/auth.guard';
import { RoleGuard } from './shared/services/auth/role.guard';

export const rootRouterConfig: Routes = [
  { 
    path: '', 
    redirectTo: 'others', 
    pathMatch: 'full' 
  },

  {
    path: '', 
    component: AuthLayoutComponent,
    children: [
      { 
        path: 'sessions', 
        loadChildren: './views/sessions/sessions.module#SessionsModule',
        data: { title: 'Session'} 
      },
      {
        path: 'print/:id',
          component: PrinterComponent,
          data: { title: 'Basic', breadcrumb: 'BASIC' }
      }
    ]
  },
  {
    path: '',
    component: PrintLayoutComponent,
    children: [
      {
        path: 'print/:id',
          component: PrinterComponent,
          data: { title: 'Basic', breadcrumb: 'BASIC' }
      }
    ]
  },

  {
    path: '', 
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'others', 
        loadChildren: './views/others/home/home.module#HomeModule', 
        data: { title: 'Others', breadcrumb: 'OTHERS'}
      },
      {
        path: 'orders', 
        loadChildren: './views/others/orders/orders.module#OrdersModule', 
       // canActivate: [RoleGuard],
        data: {role: 'Procurement'}
      },
      {
        path: 'manage', 
        loadChildren: './views/others/manage/manage.module#ManageModule', 
      //  canActivate: [RoleGuard],
        data: {role: 'Super Admin'}  },
      {
        path: 'vehicle', 
        loadChildren: './views/others/vehicle/vehicle.module#VehicleModule', 
       // canActivate: [RoleGuard],
        data: {role: 'Advisor'}
       // data: { title: 'Vehicle', breadcrumb: 'VEHICLE'}
      },
      {
        path: 'finance', 
        loadChildren: './views/others/finance/finance.module#FinanceModule', 
        data: { title: 'Finance', breadcrumb: 'FINANCE'}
      }
      ,
      {
        path: 'companyusers', 
        loadChildren: './views/others/companyusers/companyusers.module#CompanyusersModule', 
      //  canActivate: [RoleGuard],
        data: {role: 'Admin'}
       // data: { title: 'Vehicle', breadcrumb: 'VEHICLE'}
      }
      ,
      {
        path: 'compsuppliers', 
        loadChildren: './views/others/compsuppliers/compsuppliers.module#CompsuppliersModule', 
      //  canActivate: [RoleGuard],
        data: {role: 'Admin'}
       // data: { title: 'Vehicle', breadcrumb: 'VEHICLE'}
      } ,
      {
        path: 'prospectiveorders', 
        loadChildren: './views/others/prospectiveorders/prospectiveorders.module#ProspectiveordersModule', 
      //  canActivate: [RoleGuard],
        data: {role: 'Admin'}
       // data: { title: 'Vehicle', breadcrumb: 'VEHICLE'}
      }
      ,
      {
        path: 'procurement', 
        loadChildren: './views/others/procurement/procurement.module#ProcurementModule', 
        data: { title: 'Procurement', breadcrumb: 'PROCUREMENT'}
      }
    ]
  },
  { 
    path: '**', 
    redirectTo: 'sessions/404'
  }
];

