import { Component, OnInit, ViewChild } from '@angular/core';
import { egretAnimations } from 'app/shared/animations/egret-animations';
import { Subscription } from 'rxjs';
import { MatSidenav, MatDialog, MatSnackBar, MatDialogRef } from '@angular/material';
import { ObservableMedia, MediaChange } from '@angular/flex-layout';
import { AppConfirmService } from 'app/shared/services/app-confirm/app-confirm.service';
import { AppLoaderService } from 'app/shared/services/app-loader/app-loader.service';
import { OrderService } from 'app/shared/services/order.service';
import { MakedialogueComponent } from './makedialogue/makedialogue.component';
import { ModeldialogueComponent } from './modeldialogue/modeldialogue.component';
import { PartsdialogueComponent } from './partsdialogue/partsdialogue.component';



export interface makemodel {

  make?:string;
  model?:string;
  
  }

@Component({
  selector: 'app-vehicledetails',
  templateUrl: './vehicledetails.component.html',
  styleUrls: ['./vehicledetails.component.scss'],
  animations: egretAnimations
})
export class VehicledetailsComponent implements OnInit {
  isMobile;
  hasvehicles: Boolean = false;
 screenSizeWatcher: Subscription;
 isSidenavOpen: Boolean = true;
  public items: any[];
  public getItemSub: Subscription;
  selectedValue: string;
  public itemsv;
  public itemsp;
  tempp;
  tempv;

  itemcars:makemodel={};
  itemcarsarr:any=[]

  @ViewChild(MatSidenav) private sideNav: MatSidenav;
  constructor(
    private media: ObservableMedia,
    private dialog: MatDialog,
    private snack: MatSnackBar,
    private orderservice: OrderService,
    private confirmService: AppConfirmService,
    private loader: AppLoaderService
  ) { }

  ngOnInit() {
    this.inboxSideNavInit();
    this.selectedValue="car_make";
    this.getItemsv();
    this.getItemsp();
   // this.getItems()
  }

getClick(sele:string){

  this.selectedValue=sele;

}
returnBol(ret:string){
  if(this.selectedValue===ret){
    return true;
  } else{
    return false;
  }
}




itemcarmethod(item:any){

  item.forEach((item1, index) => {
    console.log("900000======== "+item1); // 9, 2, 5

    for (var j = 0; j <= item1.vehicle_model.length; j++) {
      console.log("90000011======== "+item1.vehicle_model[j]);
      this.itemcars.model=item1.vehicle_model[j];
      this.itemcars.make=item1.vehicle_make;
      this.itemcarsarr.push(this.itemcars);
     // this.itemcars.model=null;
     // this.itemcars.make=null;
    }

  })

/*
  item.forEach((item1, indexs) => {
    console.log("900000======== "+item1); // 9, 2, 5
  //  console.log("-------------------"+JSON.stringify( item1));
  //  console.log(index); // 0, 1, 2

   item1.vehicle_model.forEach((item2, index) => {
     // console.log(item); // 9, 2, 5
      //console.log(index); // 0, 1, 2
   //   console.log("-------============="+JSON.stringify( item2));
 this.itemcars.model=item2[index];
  this.itemcars.make=item1.vehicle_make[indexs];
  this.itemcarsarr.push(this.itemcars);
  
  
  }); 

});
*/
//console.log("-------============="+JSON.stringify( this.itemcars));
//console.log("!!!!!!!!!!!!!!!!!!!!"+JSON.stringify(this.itemcarsarr));
    }
  

 
  getItemsp() {
    
    //this.items =this.temp =this.orderservice.getOrders2();
    var sub=this.orderservice.getallPart().subscribe(
      data => {
        sub.unsubscribe()
         // refresh the list
          if(data){
        
         this.itemsp =this.tempp = data;
          }
        // this.orderservice.orders = data;
        // this.orderservice.setOrders(data);
     //   console.log("-------------------"+JSON.stringify( data));
         //console.log("-------------------"+JSON.stringify( this.orderservice.orders));
         
         return true;
       },
       error => {
         console.error("Error saving !");
       //  return Observable.throw(error);
      }
    );
     
     // console.log(  +"-------------------");

      }



      getItemsv() {
        //this.items =this.temp =this.orderservice.getOrders2();
        var subv= this.orderservice.getallVehicles().subscribe(
          data => {
            subv.unsubscribe;
             // refresh the list
             

             if(data){
        
             
             this.itemsv =this.tempv = data;
             }
          //   this.itemcarmethod(data);
          //   console.log("-------------------"+JSON.stringify( data));
             
             return true;
           },
           error => {
             console.error("Error saving !");
           //  return Observable.throw(error);
          }
        );
         
         // console.log(  +"-------------------");
    
          }



  ngOnDestroy() {
    if (this.getItemSub) {
      this.getItemSub.unsubscribe()
    }
  }
/*  getItems() {
    this.getItemSub = this.crudService.getItems()
      .subscribe(data => {
        this.items = data;
      })
  }
 */
  
openmakePopUp(data: any = {}, isNew?) {
  let title = isNew ? 'Add Vehicle Make' : 'Update Vehicle Make';
  let dialogRef: MatDialogRef<any> = this.dialog.open(MakedialogueComponent, {
    width: '720px',
    disableClose: true,
    data: { title: title, payload: data }
  })
  dialogRef.afterClosed()
    .subscribe(res => {
      if(!res) {
        // If user press cancel
        return;
      }else{
      this.getItemsv(); }
     // this.getItemsp();
      this.loader.open();
      if (isNew) {
    /*    this.crudService.addItem(res)
          .subscribe(data => {
            this.items = data;
            this.loader.close();
            this.snack.open('Member Added!', 'OK', { duration: 4000 })
          }) */
      } else {
      /*  this.crudService.updateItem(data._id, res)
          .subscribe(data => {
            this.items = data;
            this.loader.close();
            this.snack.open('Member Updated!', 'OK', { duration: 4000 })
          }) */
      }
    })
}

openmodelPopUp(data: any = {}, isNew?) {
  let title = isNew ? 'Add Vehicle Model' : 'Update Vehicle Model';
  let dialogRef: MatDialogRef<any> = this.dialog.open(ModeldialogueComponent, {
    width: '720px',
    disableClose: true,
    data: { title: title, payload: data }
  })
  dialogRef.afterClosed()
    .subscribe(res => {
      if(!res) {
        // If user press cancel
        return;
      }
      else{
      this.getItemsv();}
     // this.getItemsp();
      this.loader.open();
      if (isNew) {
    /*    this.crudService.addItem(res)
          .subscribe(data => {
            this.items = data;
            this.loader.close();
            this.snack.open('Member Added!', 'OK', { duration: 4000 })
          }) */
      } else {
      /*  this.crudService.updateItem(data._id, res)
          .subscribe(data => {
            this.items = data;
            this.loader.close();
            this.snack.open('Member Updated!', 'OK', { duration: 4000 })
          }) */
      }
    })
}


opencarpartPopUp(data: any = {}, isNew?) {
  let title = isNew ? 'Add Vehicle Part' : 'Update Vehicle Part';
  let dialogRef: MatDialogRef<any> = this.dialog.open(PartsdialogueComponent, {
    width: '720px',
    disableClose: true,
    data: { title: title, payload: data }
  })
  dialogRef.afterClosed()
    .subscribe(res => {
      if(!res) {
        // If user press cancel
        return;
      }else{
     // this.getItemsv();
      this.getItemsp();}
      this.loader.open(); 
      if (isNew) {
    /*    this.crudService.addItem(res)
          .subscribe(data => {
            this.items = data;
            this.loader.close();
            this.snack.open('Member Added!', 'OK', { duration: 4000 })
          }) */
      } else {
      /*  this.crudService.updateItem(data._id, res)
          .subscribe(data => {
            this.items = data;
            this.loader.close();
            this.snack.open('Member Updated!', 'OK', { duration: 4000 })
          }) */
      }
    })
}


  deleteItem(row) {
   /* this.confirmService.confirm({message: `Delete ${row.name}?`})
      .subscribe(res => {
        if (res) {
          this.loader.open();
       /*   this.crudService.removeItem(row)
            .subscribe(data => {
              this.items = data;
              this.loader.close();
              this.snack.open('Member deleted!', 'OK', { duration: 4000 })
            })  */
     //   }
    //  }) 
  }



  updateSidenav() {
    let self = this;
    setTimeout(() => {
      self.isSidenavOpen = !self.isMobile;
      self.sideNav.mode = self.isMobile ? 'over' : 'side';
    })
  }
  inboxSideNavInit() {
    this.isMobile = this.media.isActive('xs') || this.media.isActive('sm');
    this.updateSidenav();
    this.screenSizeWatcher = this.media.subscribe((change: MediaChange) => {
      this.isMobile = (change.mqAlias == 'xs') || (change.mqAlias == 'sm');
      this.updateSidenav();
    });
  }



}