import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Globals } from 'app/globals';

interface IMenuItem {
  type: string,       // Possible values: link/dropDown/icon/separator/extLink
  name?: string,      // Used as display text for item and title for separator type
  state?: string,     // Router state
  icon?: string,      // Material icon name
  tooltip?: string,   // Tooltip text 
  disabled?: boolean, // If true, item will not be appeared in sidenav.
  sub?: IChildItem[], // Dropdown items
  badges?: IBadge[]
}
interface IChildItem {
  type?: string,
  name: string,       // Display text
  state?: string,     // Router state
  icon?: string,
  sub?: IChildItem[]
}

interface IBadge {
  color: string;      // primary/accent/warn/hex color codes(#fff000)
  value: string;      // Display text
}
//iconMenu2 IMenuItem
@Injectable()
export class NavigationService {
 // iconMenu :IMenuItem[]=[];
 kk;
  constructor(private globals: Globals) { }
/*
setIconMenu(){
  ///this.globals.userdetails.userAuthority
if('Advisor'==='Advisor'){
  this.iconMenu=[
    {
      name: 'DASHBOARD',
      type: 'link',
      tooltip: 'Dashboard',
      icon: 'dashboard',
      state: 'others'
    },
    {
      name: 'Vehicle',
      type: 'dropDown',
      tooltip: 'Vehicle',
      icon: 'format_line_spacing',
      state: 'vehicle',
      sub: [
        { name: 'New Vehicle', state: 'newvehicle' },
       // { name: 'Company Users', state: 'users' },
       // { name: 'Register Supplier', state: 'suppliers' },
        { name: 'Vehicle Details', state: 'vehicledetails' }
      ]
    }
    
  ]
}else if( this.globals.userdetails.userAuthority==='Procurement' ){
  this.iconMenu=[
    {
      name: 'DASHBOARD',
      type: 'link',
      tooltip: 'Dashboard',
      icon: 'dashboard',
      state: 'others'
    },
    {
      name: 'Procure',
      type: 'link',
      tooltip: 'Procure',
      icon: 'format_line_spacing',
      state: 'procurement'
    },
    {
      name: 'Orders',
      type: 'link',
      tooltip: 'Documentation',
      icon: 'library_books',
      state: 'orders'
    }
  ]

} else if(this.globals.userdetails.userAuthority==='Admin'){
  this.iconMenu=[
    {
      name: 'DASHBOARD',
      type: 'link',
      tooltip: 'Dashboard',
      icon: 'dashboard',
      state: 'others'
    },
    
    {
      name: 'Users',
      type: 'link',
      tooltip: 'Users',
      icon: 'person',
      state: 'companyusers'
    },  
    {
      name: 'Vehicle',
      type: 'dropDown',
      tooltip: 'Vehicle',
      icon: 'format_line_spacing',
      state: 'vehicle',
      sub: [
        { name: 'New Vehicle', state: 'newvehicle' },
       // { name: 'Company Users', state: 'users' },
       // { name: 'Register Supplier', state: 'suppliers' },
        { name: 'Vehicle Details', state: 'vehicledetails' }
      ]
    },
    {
      name: 'Procure',
      type: 'link',
      tooltip: 'Procure',
      icon: 'format_line_spacing',
      state: 'procurement'
    },
    {
      name: 'Orders',
      type: 'link',
      tooltip: 'Documentation',
      icon: 'library_books',
      state: 'orders'
    }



  ]
} else if(this.globals.userdetails.userAuthority==='Super Admin'){
  this.iconMenu=[

    {
      name: 'DASHBOARD',
      type: 'link',
      tooltip: 'Dashboard',
      icon: 'dashboard',
      state: 'others'
    },
    
    {
      name: 'Users',
      type: 'link',
      tooltip: 'Users',
      icon: 'person',
      state: 'companyusers'
    },  
    {
      name: 'Vehicle',
      type: 'dropDown',
      tooltip: 'Vehicle',
      icon: 'format_line_spacing',
      state: 'vehicle',
      sub: [
        { name: 'New Vehicle', state: 'newvehicle' },
       // { name: 'Company Users', state: 'users' },
       // { name: 'Register Supplier', state: 'suppliers' },
        { name: 'Vehicle Details', state: 'vehicledetails' }
      ]
    },
    {
      name: 'Procure',
      type: 'link',
      tooltip: 'Procure',
      icon: 'format_line_spacing',
      state: 'procurement'
    },
    {
      name: 'Orders',
      type: 'link',
      tooltip: 'Documentation',
      icon: 'library_books',
      state: 'orders'
    },
    {
      name: 'Manage',
      type: 'dropDown',
      tooltip: 'Manage',
      icon: 'format_line_spacing',
      state: 'manage',
      sub: [
        { name: 'Add Company', state: 'addcompany' },
        { name: 'Company Users', state: 'users' },
        { name: 'Register Supplier', state: 'suppliers' },
        { name: 'Vehicle Details', state: 'vehicles' }
      ]
    }

  ]
}


}
*/
//iconMenu=[

  iconMenuassessment: IMenuItem[] = [
    {
      name: 'DASHBOARD',
      type: 'link',
      tooltip: 'Dashboard',
      icon: 'dashboard',
      state: 'others'
    },
      
    {
      name: 'Stock',
      type: 'dropDown',
      tooltip: 'Vehicle',
      icon: 'format_line_spacing',
      state: 'vehicle',
      sub: [
        { name: 'New Stock', state: 'newvehicle' },
       // { name: 'Company Users', state: 'users' },
       // { name: 'Register Supplier', state: 'suppliers' },
        { name: 'Vehicle Details', state: 'vehicledetails' }
      ]
    },
    {
      name: 'Prospective Orders',
      type: 'link',
      tooltip: 'Prospective Orders',
      icon: 'person',
      state: 'prospectiveorders'
    }, 
    {
      name: 'Procure',
      type: 'link',
      tooltip: 'Procure',
      icon: 'format_line_spacing',
      state: 'procurement'
    }


  ]

  iconMenuassessmentAdvisor: IMenuItem[] = [
    {
      name: 'DASHBOARD',
      type: 'link',
      tooltip: 'Dashboard',
      icon: 'dashboard',
      state: 'others'
    },
      
    
    {
      name: 'Procure',
      type: 'link',
      tooltip: 'Procure',
      icon: 'format_line_spacing',
      state: 'procurement'
    }


  ]

  iconMenuprocurement: IMenuItem[] = [
    {
      name: 'DASHBOARD',
      type: 'link',
      tooltip: 'Dashboard',
      icon: 'dashboard',
      state: 'others'
    },
    
    {
      name: 'Orders',
      type: 'link',
      tooltip: 'Documentation',
      icon: 'library_books',
      state: 'orders'
    } ,
    {
      name: 'Suppliers',
      type: 'link',
      tooltip: 'Suppliers',
      icon: 'person',
      state: 'compsuppliers'
    }
  /*  {
      name: 'Vehicle',
      type: 'dropDown',
      tooltip: 'Vehicle',
      icon: 'format_line_spacing',
      state: 'vehicle',
      sub: [
        { name: 'New Vehicle', state: 'newvehicle' },
       // { name: 'Company Users', state: 'users' },
       // { name: 'Register Supplier', state: 'suppliers' },
        { name: 'Vehicle Details', state: 'vehicledetails' }
      ]
    },
    {
      name: 'Procure',
      type: 'link',
      tooltip: 'Procure',
      icon: 'format_line_spacing',
      state: 'procurement'
    },
    {
      name: 'Orders',
      type: 'link',
      tooltip: 'Documentation',
      icon: 'library_books',
      state: 'orders'
    }
*/


  ]


  iconMenuIT: IMenuItem[] = [
    {
      name: 'DASHBOARD',
      type: 'link',
      tooltip: 'Dashboard',
      icon: 'dashboard',
      state: 'others'
    },
    
    {
      name: 'Users',
      type: 'link',
      tooltip: 'Users',
      icon: 'person',
      state: 'companyusers'
    }, 
  /*  {
      name: 'Vehicle',
      type: 'dropDown',
      tooltip: 'Vehicle',
      icon: 'format_line_spacing',
      state: 'vehicle',
      sub: [
        { name: 'New Vehicle', state: 'newvehicle' },
       // { name: 'Company Users', state: 'users' },
       // { name: 'Register Supplier', state: 'suppliers' },
        { name: 'Vehicle Details', state: 'vehicledetails' }
      ]
    },
    {
      name: 'Procure',
      type: 'link',
      tooltip: 'Procure',
      icon: 'format_line_spacing',
      state: 'procurement'
    },
    {
      name: 'Orders',
      type: 'link',
      tooltip: 'Documentation',
      icon: 'library_books',
      state: 'orders'
    }
*/


  ]


  iconMenuAdministration: IMenuItem[] = [
    {
      name: 'DASHBOARD',
      type: 'link',
      tooltip: 'Dashboard',
      icon: 'dashboard',
      state: 'others'
    },{
      name: 'Stock',
      type: 'dropDown',
      tooltip: 'Vehicle',
      icon: 'format_line_spacing',
      state: 'vehicle',
      sub: [
        { name: 'New Stock', state: 'newvehicle' },
       // { name: 'Company Users', state: 'users' },
       // { name: 'Register Supplier', state: 'suppliers' },
        { name: 'Vehicle Details', state: 'vehicledetails' }
      ]
    },
    {
      name: 'Users',
      type: 'link',
      tooltip: 'Users',
      icon: 'person',
      state: 'companyusers'
    }, 
    {
      name: 'Sell',
      type: 'link',
      tooltip: 'Sell',
      icon: 'dashboard',
      state: 'compsuppliers'
    }, 
    {
      name: 'Prospective Orders',
      type: 'link',
      tooltip: 'Prospective Orders',
      icon: 'person',
      state: 'prospectiveorders'
    }, 
    {
      name: 'Procure',
      type: 'link',
      tooltip: 'Procure',
      icon: 'format_line_spacing',
      state: 'procurement'
    }
    ,
  
    {
      name: 'View Stock',
      type: 'link',
      tooltip: 'Documentation',
      icon: 'adjust',
      state: 'orders'
    },
    {
      name: 'Finance',
      type: 'dropDown',
      tooltip: 'Finance',
      icon: 'adjust',
      state: 'finance',
      sub: [
        { name: 'Open Bid', state: 'openbids' },
       // { name: 'Company Users', state: 'users' },
       // { name: 'Register Supplier', state: 'suppliers' },
        { name: 'View Bidding Report', state: 'viewbiddingreport' }
      ]
    }


  ]


 iconMenusuperAdmin: IMenuItem[] = [
    {
      name: 'HOME',
      type: 'iconw',
      tooltip: 'Home',
      icon: 'home',
      state: 'home'
    },
    {
      name: 'PROFILE',
      type: 'iconw',
      tooltip: 'Profile',
      icon: 'person',
      state: 'profile/overview'
    },
    {
      name: 'TOUR',
      type: 'iconw',
      tooltip: 'Tour',
      icon: 'flight_takeoff',
      state: 'tour'
    },
    {
      type: 'separator',
      name: 'Main Links'
    },
    {
      name: 'DASHBOARD',
      type: 'link',
      tooltip: 'Dashboard',
      icon: 'dashboard',
      state: 'others'
    },
    {
      name: 'Stock',
      type: 'dropDown',
      tooltip: 'Vehicle',
      icon: 'format_line_spacing',
      state: 'vehicle',
      sub: [
        { name: 'New Stock', state: 'newvehicle' },
       // { name: 'Company Users', state: 'users' },
       // { name: 'Register Supplier', state: 'suppliers' },
        { name: 'Vehicle Details', state: 'vehicledetails' }
      ]
    },
   /* {
      name: 'Users',
      type: 'link',
      tooltip: 'Users',
      icon: 'person',
      state: 'companyusers'
    }, */
    {
      name: 'Sell',
      type: 'link',
      tooltip: 'Sell',
      icon: 'dashboard',
      state: 'compsuppliers'
    }, 
   /* {
      name: 'Prospective Orders',
      type: 'link',
      tooltip: 'Prospective Orders',
      icon: 'person',
      state: 'prospectiveorders'
    }, 
    {
      name: 'Procure',
      type: 'link',
      tooltip: 'Procure',
      icon: 'format_line_spacing',
      state: 'procurement'
    }
    , */
    
   /* { //this.globals.gettypeManage(),
      name: 'Manage',
      type: 'dropDown',
      tooltip: 'Manage',
      icon: 'format_line_spacing',
      state: 'manage',
      sub: [
        { name: 'Add Company', state: 'addcompany' },
        { name: 'Company Users', state: 'users' },
        { name: 'Register Supplier', state: 'suppliers' },
        { name: 'Vehicle Details', state: 'vehicles' }
      ]
    }, */
    {
      name: 'View Stock',
      type: 'link',
      tooltip: 'Documentation',
      icon: 'adjust',
      state: 'orders'
    },
  /*  {
      name: 'Finance',
      type: 'dropDown',
      tooltip: 'Finance',
      icon: 'adjust',
      state: 'finance',
      sub: [
        { name: 'Open Bid', state: 'openbids' },
       // { name: 'Company Users', state: 'users' },
       // { name: 'Register Supplier', state: 'suppliers' },
        { name: 'View Bidding Report', state: 'viewbiddingreport' }
      ]
    } */
  ]
  iconMenu: IMenuItem[] = [
    {
      name: 'DASHBOARD',
      type: 'link',
      tooltip: 'Dashboard',
      icon: 'dashboard',
      state: 'others'
    }

  ]

  // Icon menu TITLE at the very top of navigation.
  // This title will appear if any icon type item is present in menu.
  iconTypeMenuTitle: string = 'Frequently Accessed';
  // sets iconMenu as default;
  //if(5===5){
  menuItems = new BehaviorSubject<IMenuItem[]>(this.iconMenu);
  // navigation component has subscribed to this Observable
  menuItems$ = this.menuItems.asObservable();
  //}
  // Customizer component uses this method to change menu.
  // You can remove this method and customizer component.
  // Or you can customize this method to supply different menu for
  // different user type.  iconMenuassessmentAdvisor
  publishNavigationChange(menuType: string) {
    switch (menuType) {
      case 'Advisor':
      this.menuItems.next(this. iconMenuassessmentAdvisor);
     break; 
      case 'Assessment':
         this.menuItems.next(this. iconMenuassessment);
        break;
       
       case 'Procurement':
         this.menuItems.next(this.iconMenuprocurement);
         break;
         case 'Administration':
         this.menuItems.next(this.iconMenuAdministration);
        break;
      case 'Super Administration':
         this.menuItems.next(this.iconMenusuperAdmin);
        break;
      default:
        this.menuItems.next(this.iconMenu);
    }
  }
}