import { Injectable } from "@angular/core";
//import { map } from 'rxjs/operators';
import 'rxjs/add/operator/shareReplay';
import 'rxjs/Rx';
import { HttpClient, HttpHeaders } from '@angular/common/http';
//import { map, catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/catch';
import { sparepartfromcorporate } from "../models/sparepartfromcorporate.model";

import { map, share, catchError } from "rxjs/operators";
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
//import { Observable } from "rxjs/Rx";
import { Companyreg } from "../models/companyreg.model";
import {  publish, multicast, shareReplay } from 'rxjs/operators';
import { authorisedData } from "app/views/others/procurement/procure/procure.component";
import { CompUser } from "../models/compuser.model";
import { supplierIn } from "app/views/others/compsuppliers/suppliers/suppliers.component";
//import { UserLogin } from "../models/userlogin.model";
//import { Observable } from 'rxjs/Observable';


let httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' ,})
  };
  
  @Injectable({
    providedIn: 'root'
  })
export class OrderService {
  public _url: string = "http://206.189.127.82:3000/";
 //public _url: string ="http://localhost:3000/";
  public  orders;bids;porders;prospectivePOrders;
  public groupSource = new BehaviorSubject<any>({});
    public group$: Observable<any> = this.groupSource.asObservable();
public onebidaward;
someDataObservable: Observable<any>;
public allmakes;
public allmodels;
public allparts;
public allmakesmodels;
constructor(private http: HttpClient){}
/*
public getOrders(){
    return this.http.post(this._url+ 'get/orderlist',{}).map((response: Response) =>{
       // console.log (response.json());
      // response;
      // console.log (JSON.stringify(response));
        });
 }
*/

setMake(val){
this.allmakes=val;
}
getMake(){
return this.allmakes;
}

setMakemodel(val){
  this.allmakesmodels=val;
  }
  getMakemodel(){
  return this.allmakesmodels;
  }

setModel(val){
this.allmodels=val;
}
getModel(){
  return this.allmodels;
}
setPart(val){
this.allparts=val;
}
getPart(){
  return this.allparts;
}

setPOrders(val){
this.porders=val;
}
givePOrders() {
  // console.log(JSON.stringify(this.orders));
   return this.porders;
}  
setprospectivePOrders(val){
  this.prospectivePOrders=val;
}
giveprospectivePOrders() {
  // console.log(JSON.stringify(this.orders));
   return this.prospectivePOrders;
} 
setOrders(val) {
    this.orders = val;
}
giveOrders() {
   // console.log(JSON.stringify(this.orders));
    return this.orders;
}  

setBids(val) {
    this.bids = val;
}
giveBids() {
   // console.log(JSON.stringify(this.orders));
    return this.bids;
}  

 public getOrders(){
    //return this.http.get(this._url+ 'get/orderlist');
    return this.http.post(this._url+ 'get/allStock', {});
        /*   this._demoService.getFoods().subscribe(
              data => { this.foods = data},
              err => console.error(err),
             () => console.log('done loading foods')
           );  */
       
 }

 public getPOrders(){

  return this.http.post(this._url+ 'get/porderlist', {"order_sourceCompanyid":localStorage.getItem('cnumber')});


 }
 
 public getprospectivePOrders(){

  return this.http.post(this._url+ 'get/prospective/porderlist', {"order_sourceCompanyid":localStorage.getItem('cnumber')});


 }

 public getCountys(){
    //return this.http.get(this._url+ 'get/orderlist');
    return this.http.post(this._url+ 'any/get/county', {}).map(res => console.log(res))
    .take(1)
        /*   this._demoService.getFoods().subscribe(
              data => { this.foods = data},
              err => console.error(err),
             () => console.log('done loading foods')
           );  */
         
 }
 

 public getPartBids(partid:string){
    //return this.http.get(this._url+ 'get/orderlist');
    return this.http.post(this._url+'get/partbids', {partid:partid});
        /*   this._demoService.getFoods().subscribe(
              data => { this.foods = data},
              err => console.error(err),
             () => console.log('done loading foods')
           );  */
        
 }
 
 
 public closeopenBids(partid:string,awardcompanyId:string,bidOrderId:string,companyOfficer:string,part_status:string){
  //return this.http.get(this._url+ 'get/orderlist');

  return this.http.post(this._url+'bid/closeopen', {bidPartId:partid,awardcompanyId:awardcompanyId,bidOrderId:bidOrderId,companyOfficer:companyOfficer,part_status:part_status});
      /*   this._demoService.getFoods().subscribe(
            data => { this.foods = data},
            err => console.error(err),
           () => console.log('done loading foods')
         );  */
       
}

 public gethistory(comclient_number:string,car_make:string,car_model:string,car_yom:string,partName:string){
    //return this.http.get(this._url+ 'get/orderlist');
    return this.http.post(this._url+'get/partpurchased/history', {comclient_number:comclient_number,car_make:car_make,car_model:car_model,car_yom:car_yom,partName:partName});
        /*   this._demoService.getFoods().subscribe(
              data => { this.foods = data},
              err => console.error(err),
             () => console.log('done loading foods')
           );  */
         
 }


public postOrder(order: sparepartfromcorporate): Observable<any> {
  /*  console.log ("q234567u8io==========================================================");
    return this.http.post<sparepartfromcorporate>(this._url+'corporate/order',order)
    .map(res => console.log(res))// ...and calling .json() on the response to return data
    .catch((error:any) => Observable.throw(error.json().error || 'Server error')) //...errors if
   // .subscribe()
    .shareReplay()
    .subscribe() */
    return this.http.post(this._url+'corporate/order', order).map(res =>  console.log(res))
    //.take(1);
    .pipe(shareReplay())
    //.map(res =>  console.log(res))
    //.take(1)
}


























public postReiceivedVehicle(order:sparepartfromcorporate): Observable<any> {



  /*  console.log ("q234567u8io==========================================================");
    return this.http.post<sparepartfromcorporate>(this._url+'corporate/order',order)
    .map(res => console.log(res))// ...and calling .json() on the response to return data
    .catch((error:any) => Observable.throw(error.json().error || 'Server error')) //...errors if
   // .subscribe()
    .shareReplay()
    .subscribe() */

    return this.http.post(this._url+'corporate/receive/vehicle', order).map(res =>  console.log(res))


    //.take(1);
    //.pipe(shareReplay())
    //.map(res =>  console.log(res))
    //.take(1)


/*
return this.http.post(this._url+'corporate/receive/vehicle', order,httpOptions).pipe(

  map(this.extractData),

  catchError(this.handleError)

)
*/


}



















public postAuthorisedParts(order:authorisedData): Observable<any> {
  /*  console.log ("q234567u8io==========================================================");
    return this.http.post<sparepartfromcorporate>(this._url+'corporate/order',order)
    .map(res => console.log(res))// ...and calling .json() on the response to return data
    .catch((error:any) => Observable.throw(error.json().error || 'Server error')) //...errors if
   // .subscribe()
    .shareReplay()
    .subscribe() */
    return this.http.post(this._url+'push/authorised/parts', order).map(res =>  console.log(res))
    //.take(1);
    .pipe(shareReplay())
    //.map(res =>  console.log(res))
    //.take(1)
}

public addCompany(company:Companyreg){
  //  /corporate/register
    return this.http.post(this._url+'corporate/register', company);

}

public createCompanyUser(user: CompUser){

  return this.http.post(this._url+'corporate/users/create', user);

}

public getUsersCompany(companynumber:string){
  //return this.http.get(this._url+ 'get/orderlist');
  return this.http.post(this._url+'get/all/users', {"companynumber":companynumber});
      /*   this._demoService.getFoods().subscribe(
            data => { this.foods = data},
            err => console.error(err),
           () => console.log('done loading foods')
         );  */
       
}

public bidAward(bidawardh:any){
    //  /corporate/register
      return this.http.post(this._url+'bid/award', bidawardh);
    }

  public bidOffer(bidoffer:any){
    //  /corporate/register
      return this.http.post(this._url+'bid/offer', bidoffer);
  
  }
  public bidOffertt(){
    //  /corporate/register
      return this.http.post(this._url+'add', {"hata":"hatari fire"}).map(res =>  console.log(res))
      .take(1);
  
  }
  getWondetails(partId:string) {
    
    //console.log(obj);
   return this.http.post(this._url+'part/won/details', {"partId":partId});
      //.subscribe(res => console.log(res));
  }

  postMakemodel(make:string,model:string) {
    
    //console.log(obj);
   return this.http.post(this._url+'create/vehicle', {"carmake":make,"carmodel":model}).map(res => console.log(res))
   .take(1)
      //.subscribe(res => console.log(res));
  }

  postPart(partname:string,category:string) {
    
    //console.log(obj);
   return this.http.post(this._url+'post/vehicle/parts', {"partname":partname,"category":category}).map(res => console.log(res))
   .take(1)
      //.subscribe(res => console.log(res));
  }

  
 
  getallPart() {
    
   return this.http.post(this._url+'get/partall',{});
      
  }

  getallVehiclefeed() {
   
    var sub= this.http.post(this._url+'get/vehicleall',{}).subscribe(
      data => {
        sub.unsubscribe()
         // refresh the list

       //  this.setPart(data);
       this.setMakemodel(data);
      
         return true;
       },
       error => {
         console.error("Error saving !");
       //  return Observable.throw(error);
      }
    );
     
   }
   


  getallPartfeed() {
    
    var sub= this.http.post(this._url+'get/partall',{}).subscribe(
      data => {
        sub.unsubscribe()
         // refresh the list

         this.setPart(data);
      
         return true;
       },
       error => {
         console.error("Error saving !");
       //  return Observable.throw(error);
      }
    );
     
   }

  getallVehicles() {
    
    //console.log(obj);
   return this.http.post(this._url+'get/vehicleall',{});
      //.subscribe(res => console.log(res));
  }
/*
  getpurchasehistory() {
    
    //console.log(obj);
   return this.http.post(this._url+'get/partpurchased/history',{});
      //.subscribe(res => console.log(res));
  } */

  
  getsuppliers() {
    
    //console.log(obj);
   return this.http.post(this._url+'get/suppliers',{"companyId":localStorage.getItem('cnumber')});
      //.subscribe(res => console.log(res));
  }

 

 addsupplier(supplier:supplierIn) {
    
    //console.log(obj);
   return this.http.post(this._url+'add/company/suppliers',supplier);
      //.subscribe(res => console.log(res));
  }

  getSomeData(): Observable<any> {
    if (this.someDataObservable) {
      return this.someDataObservable;
    } else {
      this.someDataObservable = this.http.post(this._url+'add', {"hata":"hatari fire"}).pipe(share());
      return this.someDataObservable;
    }
  }

  createUser(): Observable<any> {
    return this.http.post<any>(this._url+'add', {"hata":"hatari fire"}).map(res => res.json())
    .take(1)
  }


  loginUserg():Observable<any>{

    return this.http.post(this._url+'add', {"hata":"hatari fire"},httpOptions).pipe(shareReplay(1),
      catchError(this.handleError)
    )
    }




     // Private
     private extractData(res: Response) {

      let body = res;
      
      return body || {};
      

	  }



	  private handleError (error: Response | any) {
	    let errMsg: string;
	    if (error instanceof Response) {
	      const err = error || '';
	      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
	    } else {
	      errMsg = error.message ? error.message : error.toString();
	    }
	   console.error(errMsg);
	    return Observable.throw(errMsg);
	  }

  





    
  
}