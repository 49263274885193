import { User } from "./user.model";
import { AuthData } from "./auth-data.model";
import { UserLogin } from "./userlogin.model";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map, share } from "rxjs/operators";
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Observable } from "rxjs/Rx";
import * as jwt_decode from "jwt-decode";
import { Router } from "@angular/router";
import { Globals } from "app/globals";

@Injectable()
export class AuthService {
    private user: User;
  private _url: string = "http://206.189.127.82:3000/";
   //private _url: string ="http://localhost:3000/";
    public userdetails:any={}
      //user =null;
      constructor(private http: HttpClient,private router: Router, private globals: Globals){}

      clear(): void {
        localStorage.clear();
      }
      setUserdetails(val) {
        this.userdetails = this.getDecodedAccessToken(val);
        this.globals.userdetails=this.userdetails;
        this.globals.userName=this.userdetails.userName;
        localStorage.setItem('token',val);
       // console.log("======================== "+JSON.stringify(this.userdetails.userAuthority));
    }
    giveUserdetails() {
       // console.log(JSON.stringify(this.orders));
        return this.userdetails;
    }  
    giveUserName(){
      //  console.log(JSON.stringify(this.userdetails ));
        return this.userdetails.userName;
    }
    getDecodedAccessToken(token: string): any {
        try{
            return jwt_decode(token);
        }
        catch(Error){
            return null;
        }
      }

    registerUser(authData: AuthData){
        this.user = {
            email: authData.email,
            userId: Math.round(Math.random() * 10000).toString()
        };

    }

   /* login(authData: AuthData) {
        this.user = {
            email: authData.email,
            userId: Math.round(Math.random() * 10000).toString()
        };

    } */

    logout() {
        this.userdetails = null;
       // this.clear();
        localStorage.removeItem('token');
        this.router.navigate(['/sessions/signin']);
    }
removetoken(){
    localStorage.removeItem('token');
}

    getUser() {
        return {...this.user};
    }

    isAuth():boolean{
       // console.log("-------------------++++  "+this.userdetails != null);
     //  return this.userdetails != null;
      /// this.userdetails != null;
      // return true;
      return localStorage.getItem('token') != null;
    }
    isAdminAuth(){
        // console.log("-------------------++++  "+this.userdetails != null);
      //  return this.userdetails != null;
       /// this.userdetails != null;
       // return true;
       //if(this.globals.userdetails.userAuthority==='Admin'){
           return this.globals.userdetails.userAuthority
      // }
       //return localStorage.getItem('token') != null;
     }



    public userLogin(userlog:UserLogin){
        //  /corporate/register
          return this.http.post(this._url+'all/users/login', userlog);
      
      }



}