import { Injectable } from '@angular/core';

@Injectable()
export class Globals {
  role: string = 'test';
  public userdetails:any={}
  public userName: string = '';



gettypeVehicle(){
return 'link';
}
gettypeUsers(){
  return 'link';
  }

  gettypeProcure(){
    return 'link';
    }
  
    gettypeDASHBOARD(){
      return 'link';
      }
    
      gettypeManage(){
       // console.log("1-----------------"+ this.userdetails);
        if(this.userdetails.userAuthority==='Super Admin'){
          return 'link';
        }else{
          return 'dropDown';
        }
       
        }
        gettypeOrders(){
          return 'link';
          }
        
          gettypeReports(){
            return 'link';
            }
          
            

}