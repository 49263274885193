import { PrintLayoutComponent } from './shared/components/layouts/print-layout/print-layout.component';
import { VehiclepicsComponent } from './views/others/orders/vehiclepics/vehiclepics.component';

import { ImageViewerModule } from "ngx-image-viewer";
  // Import your library
 // import { ImageViewerModule } from 'ng2-image-viewer';

import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GestureConfig, MatCardModule, MatIconModule, MatButtonModule, MatFormFieldModule, MatSlideToggleModule, MatInputModule, MatMenuModule, MatChipsModule, MatListModule, MatTooltipModule, MatDialogModule, MatSnackBarModule, MatSelectModule, MatAutocompleteModule } from '@angular/material';
import { 
  PerfectScrollbarModule, 
  PERFECT_SCROLLBAR_CONFIG, 
  PerfectScrollbarConfigInterface
} from 'ngx-perfect-scrollbar';
import { NgxNavigationWithDataComponent } from "ngx-navigation-with-data";
import { InMemoryWebApiModule } from 'angular-in-memory-web-api';
import { InMemoryDataService } from './shared/inmemory-db/inmemory-db.service';
import {NgxPrintModule} from 'ngx-print';
import { rootRouterConfig } from './app.routing';
import { SharedModule } from './shared/shared.module';
import { AppComponent } from './app.component';

import { PrinterComponent } from './views/others/orders/printer/printer.component';


import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AuthService } from './shared/services/auth/auth.service';
import { OrderService } from './shared/services/order.service';
import { BidpurchaseDialogComponent } from './views/others/orders/particularpart/bidpurchase-dialog/bidpurchase-dialog.component';
import { BidofferdialogComponent } from './views/others/orders/particularpart/bidofferdialog/bidofferdialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { Globals } from './globals';

import { RoleGuard } from './shared/services/auth/role.guard';
import { PartsdialogueComponent } from './views/others/vehicle/vehicledetails/partsdialogue/partsdialogue.component';
import { ModeldialogueComponent } from './views/others/vehicle/vehicledetails/modeldialogue/modeldialogue.component';
import { MakedialogueComponent } from './views/others/vehicle/vehicledetails/makedialogue/makedialogue.component';
import { UserPopupComponent } from './views/others/companyusers/users/user-popup/user-popup.component';
import { SupplierpopupComponent } from './views/others/compsuppliers/suppliers/supplierpopup/supplierpopup.component';
import { VehicleModule } from './views/others/vehicle/vehicle.module';
import { GetpreviousquotesComponent } from './views/others/prospectiveorders/getpreviousquotes/getpreviousquotes.component';

//import { BidpurchaseDialogComponent } from './bidpurchase-dialog/bidpurchase-dialog.component';




// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@NgModule({

  
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    HttpClientModule,
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    FormsModule, 
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSlideToggleModule,
    CommonModule,
   
    FlexLayoutModule,
    NgxDatatableModule,
    MatInputModule,
    NgxPrintModule,
    MatMenuModule,
    MatSelectModule,
    MatChipsModule,
    MatListModule,
    MatTooltipModule,
    MatDialogModule,
    MatSnackBarModule,
   

   ImageViewerModule.forRoot({  btnShow: {
      zoomIn: false,
      zoomOut: false,
      rotateClockwise: false,
      rotateCounterClockwise: false,
      next: true,
      prev: true
    }

  }),

  NgxPrintModule,

/*
  ImageViewerModule,*/
   // MatDialogModule
   VehicleModule,
    PerfectScrollbarModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    InMemoryWebApiModule.forRoot(InMemoryDataService, { passThruUnknownUrl: true }),
    RouterModule.forRoot(rootRouterConfig, { useHash: false })
  ],
  exports: [
    MatButtonModule,
    MatFormFieldModule,
    FormsModule
   // MatInputModule,
   // MatRippleModule,
  ],
  declarations: [AppComponent,BidpurchaseDialogComponent,BidofferdialogComponent,
    UserPopupComponent,MakedialogueComponent,ModeldialogueComponent,
    PartsdialogueComponent,SupplierpopupComponent,GetpreviousquotesComponent,
    VehiclepicsComponent,PrinterComponent,PrintLayoutComponent],

  entryComponents : [BidpurchaseDialogComponent,VehiclepicsComponent,BidofferdialogComponent,UserPopupComponent,MakedialogueComponent,ModeldialogueComponent,PartsdialogueComponent,SupplierpopupComponent,GetpreviousquotesComponent],
  providers: [Globals , NgxNavigationWithDataComponent,
    AuthService,OrderService,RoleGuard,
    { provide: HAMMER_GESTURE_CONFIG, useClass: GestureConfig },
    { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }