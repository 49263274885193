import { Component, OnInit, Inject } from '@angular/core';
import {FormGroup, FormControl, Validators} from '@angular/forms';
import { OrderService } from 'app/shared/services/order.service';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';
import { DialogData } from '../particularpart.component';
import { BidpurchaseDialogComponent } from '../bidpurchase-dialog/bidpurchase-dialog.component';
import { egretAnimations } from 'app/shared/animations/egret-animations';


export interface DialogDataOffer {
  bid_id?:string;
  awardcompanyId?:string;
  bidSupplierId?:string;
  bidOrderId?:string;
  bidPartId?:string;
  bidamount?:string;
  carmake?: string;
  carmodel?: string;
  caryom?:string;
  partname?:string;
  bidpriceper?: any;
  bidtotalprice?: any;
  suppliername?:string;
  quantity?:any;
  comment?: string;
  offeramount?:string;
  
}



@Component({
  selector: 'app-bidofferdialog',
  templateUrl: './bidofferdialog.component.html',
  styleUrls: ['./bidofferdialog.component.scss'],
  animations: egretAnimations
})
export class BidofferdialogComponent implements OnInit {
  offerdata:DialogDataOffer ={};
  basicFormo: FormGroup;
   offerpricedata:string;
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData,private dialogRef: MatDialogRef<BidofferdialogComponent>, private orderservice: OrderService,private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.basicFormo = new FormGroup({
      offerprice: new FormControl('', 
        Validators.required
      )})   
      console.log(this.data. bidtotalprice+"---------------------------"+this.data.partname);
  }



  placeoffer(){

this.offerdata.bid_id=this.data.bid_id;
this.offerdata.awardcompanyId=this.data.awardcompanyId;
this.offerdata. bidSupplierId=this.data.bidSupplierId;
this.offerdata. bidOrderId=this.data.bidOrderId;
this.offerdata.  bidPartId=this.data.bidPartId;
this.offerdata. bidamount=this.data.bidamount;
this.offerdata. carmake=this.data.carmake;
this.offerdata. carmodel=this.data.carmodel;
this.offerdata. caryom=this.data.caryom;
this.offerdata. partname=this.data.partname;
this.offerdata. bidpriceper=this.data.bidpriceper;
this.offerdata. bidtotalprice=this.data.bidtotalprice;
this.offerdata.suppliername=this.data.suppliername;
this.offerdata. comment=this.data.comment;
this.offerdata. quantity=this.data.quantity;
this.offerdata. offeramount=this.offerpricedata;


    this.orderservice.bidOffer(this.offerdata).subscribe(
      data => {
        this.openSnackBar("Success")
         // refresh the list
        // this.items =this.temp = data;
     //   this.orderservice.orders = data;
         //console.log("-------------------"+JSON.stringify( this.orderservice.orders));
       //  this.orderservice.setOrders("wewewewe");  
         this.dialogRef.close();
         return true;
       },
       error => {
         console.error("Error saving !");
       //  return Observable.throw(error);
      }
    );
  
  }  
  close(){
    this.dialogRef.close();
  }

  openSnackBar(text:string) {
    this.snackBar.open(text,'' ,{
      duration: 3000,panelClass: ['blue-snackbar'], verticalPosition: 'top', horizontalPosition: 'end'
    });
  }
}
