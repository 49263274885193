import { Component, OnInit, Inject} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';
import { DialogData } from '../particularpart.component';
import { OrderService } from 'app/shared/services/order.service';
import { egretAnimations } from 'app/shared/animations/egret-animations';
import { AppLoaderService } from 'app/shared/services/app-loader/app-loader.service';

@Component({
  selector: 'app-bidpurchase-dialog',
  templateUrl: './bidpurchase-dialog.component.html',
  styleUrls: ['./bidpurchase-dialog.component.scss'],
  animations: egretAnimations
})
export class BidpurchaseDialogComponent implements OnInit {
  public dataz;
  subscription:any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData,private dialogRef: MatDialogRef<BidpurchaseDialogComponent>, private orderservice: OrderService ,private loader: AppLoaderService, private snackBar: MatSnackBar) { }

  ngOnInit() {
    console.log(this.data. bidtotalprice+"---------------------------"+this.data.partname);
  }
/*
  bid_id:bid_id,
  awardcompanyId:this.Order.order_sourceCompanyid,
  bidSupplierId:bidSupplierId,
  bidOrderId:bidOrderId,
  bidPartId:bidPartId,
  bidamount:bidAmount,  */

awardbid(){
  this.loader.open();
 this.subscription= this.orderservice.bidAward(this.data).subscribe(
    data => {
      this.loader.close();
      this.openSnackBar("Success")
       // refresh the list
      // this.items =this.temp = data;
   /*    this.orderservice.orders = data;
       //console.log("-------------------"+JSON.stringify( this.orderservice.orders));
       this.orderservice.setOrders("wewewewe");  */
       
       this.subscription.unsubscribe();
       this.dialogRef.close({ data: 'Success' });
       return true;
     },
     error => {
       console.error("Error saving !");
     //  return Observable.throw(error);
    }
  );

}
close(){

  this.dialogRef.close({ data: 'Success' });

}
/*
openSnackBar(text:string) {
  this.snackBar.open(text,'' ,{
    duration: 3000, verticalPosition: 'top', horizontalPosition: 'end'
  });
}
*/
ngOnDestroy(): void {
  //Called once, before the instance is destroyed.
  //Add 'implements OnDestroy' to the class.
 // this.subscription.unsubscribe();
}

openSnackBar(text:string) {
  this.snackBar.open(text,'' ,{
    duration: 3000,panelClass: ['blue-snackbar'], verticalPosition: 'top', horizontalPosition: 'end'
  });
}

}
