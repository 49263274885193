import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateChild } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard implements CanActivate{
  public authToken;
  private isAuthenticated = false; // Set this value dynamically
  
  constructor(private router: Router, private authservice: AuthService) {
/*
    if (authservice.isAuth){
      this.isAuthenticated = true;
          }else{
            this.isAuthenticated = false;
          }  */

  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.authservice.isAuth()) {
      return true
    } 
    this.router.navigate(['/sessions/signin']);
    return false;
   
 
  }
 /* canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
   // if (this.authservice.isAuth()) {
   //   return true
   // } 
  //  this.router.navigate(['/sessions/signin']);

   /// return false;
   
 
  } */
}