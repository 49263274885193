import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBarModule, MatSnackBar } from '@angular/material';
import { Subscription } from 'rxjs';
import { OrderService } from 'app/shared/services/order.service';
import { CompUser } from 'app/shared/models/compuser.model';
import { AppLoaderService } from 'app/shared/services/app-loader/app-loader.service';

@Component({
  selector: 'app-user-popup',
  templateUrl: './user-popup.component.html',
  styleUrls: ['./user-popup.component.scss']
})
export class UserPopupComponent implements OnInit {
  public itemForm: FormGroup;
  public subscription: Subscription;
  selecteddep;
  selectedauth;
  departments=["Advisor","Assessment","IT","Procurement","Audit","Administration","Human Resource","Finance"];
  authoritys=["Admin","Advisor","Assessor","Procurement"];
  usercomp: CompUser ={}

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<UserPopupComponent>,
    private fb: FormBuilder, private orderservice: OrderService ,private loader: AppLoaderService,private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.buildItemForm(this.data.payload)
  }
  buildItemForm(item) {
    this.itemForm = this.fb.group({
      username: [item.username || '', Validators.required],
      
      email: [item.email || '', Validators.required]
    })
  }


  


  submitb() {
    //.. this.dialogRef.close(this.itemForm.value)
    this.loader.open();
this.usercomp.company_name=localStorage.getItem('cname');
this.usercomp.companynumber=localStorage.getItem('cnumber');
this.usercomp.userAuthority=this.selectedauth;
this.usercomp.userDepartment=this.selecteddep;
this.usercomp.userEmail=this.itemForm.value.email;
this.usercomp.userName=this.itemForm.value.username;
//this.usercomp.userPhone=

    this.subscription= this.orderservice.createCompanyUser(this.usercomp).subscribe(
      data => {
        // refresh the list
        this.loader.close();
        this.openSnackBar("Success")
       // this.items =this.temp = data;
     //   console.log(JSON.stringify( data )+"-------------------");
    this.subscription.unsubscribe();
    // this.carsparesarray.car_make = null;
    this.dialogRef.close();
     // this.clearObjectValues(this.carsparesarray);
    console.log(data+"----------------------=-===============-------jjjjjjjjj ");
     // this.basicForm.reset();
        return true;
      },
      error => {
        console.error("Error saving !");
      //  return Observable.throw(error);
     }
    );   

  }


  changeClientdep(data){
    this.selecteddep=data;
  }

  changeClientauth(data){
    this.selectedauth=data;
  }
  openSnackBar(text:string) {
    this.snackBar.open(text,'' ,{
      duration: 3000, verticalPosition: 'top', horizontalPosition: 'end'
    });
  }

}
