import { Component, OnInit, Inject } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { OrderService } from 'app/shared/services/order.service';
import { Subscription } from 'rxjs';



@Component({
  selector: 'app-modeldialogue',
  templateUrl: './modeldialogue.component.html',
  styleUrls: ['./modeldialogue.component.scss']
})



export class ModeldialogueComponent implements OnInit {
  public itemForm: FormGroup;
  //makes=["AUDI","TOYOTA","SUBARU"];
  makes;
  subscription:Subscription;
  selectedmod:string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ModeldialogueComponent>,
    private fb: FormBuilder,
    private orderservice: OrderService ,private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.buildItemForm(this.data.payload)
   this.makes=this.orderservice.getMakemodel();
   console.log("-------------------"+JSON.stringify( this.makes));
  }
  buildItemForm(item) {
    this.itemForm = this.fb.group({
      carmodel: [item.carmodel || '', Validators.required]
    })
  }


  submit() {
    // this.dialogRef.close(this.itemForm.value)
 
    this.subscription= this.orderservice.postMakemodel(this.selectedmod, this.itemForm.value.carmodel).subscribe(
     data => {
        // refresh the list
       // this.items =this.temp = data;
    /*    this.orderservice.orders = data;
        //console.log("-------------------"+JSON.stringify( this.orderservice.orders));
        this.orderservice.setOrders("wewewewe");  */
        this.orderservice.getallVehiclefeed();
        this.openSnackBar("Success")
        this.subscription.unsubscribe();
        this.dialogRef.close(true);
        return true;
      },
      error => {
        console.error("Error saving !");
      //  return Observable.throw(error);
     }
   );
 
   }
 

   checkselect(){
     if(!this.selectedmod){
       return true;
     }else{
       return false;
     }
   }
   changeClient(data){
  //  console.error("============================================ changeClient !"+data);
    this.selectedmod=data;
    //..alert("selected --->"+data);
  }



   openSnackBar(text:string) {
    this.snackBar.open(text,'' ,{
      duration: 3000, verticalPosition: 'top', horizontalPosition: 'end'
    });
  }


}
