import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, Route } from '@angular/router';
import { Observable } from 'rxjs';
import { Globals } from 'app/globals';
import { AuthService } from './auth.service';

@Injectable()
export class RoleGuard implements CanActivate {


  constructor(private _authService: AuthService, private _router: Router,private globals: Globals) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    //const user = this._authService.decode();
   // console.log(this.globals.userdetails.userAuthority+" ======================== "+next.data.role);
    if (this.globals.userdetails.userAuthority === next.data.role) {
      return true;
    }
else{
    // navigate to not found page
    this._router.navigate(['/sessions/404']);
    return false;
}
  }

}