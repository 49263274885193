import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-partimgpopup',
  templateUrl: './partimgpopup.component.html',
  styleUrls: ['../carimgpopup/d.css']
})
export class PartimgpopupComponent implements OnInit {

  @ViewChild('file') file:ElementRef


  public files: Set<File> = new Set()

progress:any
canBeClosed = true
primaryButtonText = 'Upload'
showCancelButton = true
uploading = false
uploadSuccessful = false
urls = new Array<any>();


  constructor(public dialogRef: MatDialogRef<PartimgpopupComponent>) { }

  ngOnInit() {
  }

//auto click the input element to initialisze file selection
  addFiles() {
    this.file.nativeElement.click();
  }



//add files to our array
  onFilesAdded(event) {


    //Display pic

      let files2 = event.target.files;



      if (files2) {

        for (let file of files2) {

          let reader = new FileReader();



          reader.onload = (e: any) => {




    //    let i = e.target.result.url.indexOf('/')

    //      let url = e.target.result.url.slice(i);

            this.urls.push({"url":e.target.result,"file":file});  ///

        //   console.log("BASE64 URLS ARE......", this.urls[0].url);


          }

          reader.readAsDataURL(file);
        }

    }
  }


//close dialog

closeDialog() {


  this.primaryButtonText = 'Finish';


  return this.dialogRef.close(this.urls);


}






}
