import { Component, OnInit, Inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { OrderService } from 'app/shared/services/order.service';
import { AppLoaderService } from 'app/shared/services/app-loader/app-loader.service';
import { supplierIn } from '../suppliers.component';

@Component({
  selector: 'app-supplierpopup',
  templateUrl: './supplierpopup.component.html',
  styleUrls: ['./supplierpopup.component.scss']
})
export class SupplierpopupComponent implements OnInit {

  public itemForm: FormGroup;
  public subscription: Subscription;
  selecteddep;
  selectedstatus;
  selectedcategory;
  supi:supplierIn={};
  categorys= ["Bodyparts","Mechanical","Electrical"];
  statuss= ["Account","No Account"];
 // usercomp: CompUser ={}
  //departments=["Assessment","Procurement","Audit","Administration","Finance"];
  //authoritys=["Admin","Advisor","Procurement"];
  // usercomp: CompUser ={}

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<SupplierpopupComponent>,
    private fb: FormBuilder, private orderservice: OrderService ,private loader: AppLoaderService
  ) { }
  ngOnInit() {
    this.buildItemForm(this.data.payload)
  }

  buildItemForm(item) {
    this.itemForm = this.fb.group({
      username: [item.username || '', Validators.required],
      
      phone: [item.phone || '', Validators.required],
      location: [item.username || '', ]
      
      //phone: [item.phone || '', Validators.required],
    })
  }




  submitb() {
    //.. this.dialogRef.close(this.itemForm.value)
    this.loader.open();
    
this.supi.companyId=localStorage.getItem('cnumber');
this.supi.comsupplierName=this.itemForm.value.username;
this.supi.comsupplierPhone=this.itemForm.value.phone;
this.supi.comsupplierlocation=this.itemForm.value.location;
this.supi.comsupplierCategory=this.selectedcategory;
this.supi.comsupplierstatus=this.selectedstatus;


    this.subscription= this.orderservice.addsupplier(this.supi).subscribe(
      data => {
        // refresh the list
        this.loader.close();
       // this.items =this.temp = data;
     //   console.log(JSON.stringify( data )+"-------------------");
    this.subscription.unsubscribe();
    // this.carsparesarray.car_make = null;
    this.dialogRef.close();
     // this.clearObjectValues(this.carsparesarray);
   // console.log(data+"----------------------=-===============-------jjjjjjjjj ");
     // this.basicForm.reset();
        return true;
      },
      error => {
        console.error("Error saving !");
      //  return Observable.throw(error);
     }
    );   


  }


  changeClientcategory(data){
    this.selectedcategory=data;
  }

 changeClientstatus(data){
    this.selectedstatus=data;
  }

}
