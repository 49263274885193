import { Component, OnInit, Inject } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { OrderService } from 'app/shared/services/order.service';

@Component({
  selector: 'app-makedialogue',
  templateUrl: './makedialogue.component.html',
  styleUrls: ['./makedialogue.component.scss']
})
export class MakedialogueComponent implements OnInit {
  public itemForm: FormGroup;
  subscription:any;
  makedata:string;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<MakedialogueComponent>,
    private fb: FormBuilder,
    private orderservice: OrderService ,private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.buildItemForm(this.data.payload)
  }
  buildItemForm(item) {
    this.itemForm = this.fb.group({
      carmake: [item.carmake || '', Validators.required]
    })
  }

  submit() {
   // this.dialogRef.close(this.itemForm.value)

   this.subscription= this.orderservice.postMakemodel(this.itemForm.value.carmake ,"" ).subscribe(
    data => {
       // refresh the list
      // this.items =this.temp = data;
   /*    this.orderservice.orders = data;
       //console.log("-------------------"+JSON.stringify( this.orderservice.orders));
       this.orderservice.setOrders("wewewewe");  */
       
       this.subscription.unsubscribe();
       this.getItemsval(); 
       this.openSnackBar("Success")
       this.dialogRef.close(true);
       return true;
     },
     error => {
       console.error("Error saving !");
     //  return Observable.throw(error);
    }
  );

  }


  getItemsval() {
    //this.items =this.temp =this.orderservice.getOrders2();
    var subv= this.orderservice.getallVehicles().subscribe(
      data => {
        subv.unsubscribe;
         // refresh the list
                      
       //  this.itemsv =this.tempv = 
        // data;
        this.orderservice.setMakemodel(data);
        //.. this.orderservice.setMake(data["vehicle_make"]);
        //.. this.orderservice.setModel(data["vehicle_model"]);
      //   this.itemcarmethod(data);
      //   console.log("-------------------"+JSON.stringify( data));
         
         return true;
       },
       error => {
         console.error("Error saving !");
       //  return Observable.throw(error);
      }
    );
     
     // console.log(  +"-------------------");

      }


  openSnackBar(text:string) {
    this.snackBar.open(text,'' ,{
      duration: 3000, verticalPosition: 'top', horizontalPosition: 'end'
    });
  }

}
