import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { 
  MatListModule,
  MatIconModule,
  MatButtonModule,
  MatCardModule,
  MatMenuModule,
  MatSlideToggleModule,
  MatGridListModule,
  MatChipsModule,
  MatCheckboxModule,
  MatRadioModule,
  MatTabsModule,
  MatInputModule,
  MatProgressBarModule,
 
  MatDatepickerModule, 
  MatNativeDateModule,
  
  MatStepperModule,
  MatSidenavModule,
  MatTooltipModule,
  MatDialogModule,
  MatSnackBarModule,
  MatFormFieldModule,
  MatSelectModule,
  MatExpansionModule,
  MatAutocomplete,
  MatAutocompleteModule,
  
 
 } from '@angular/material';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ChartsModule } from 'ng2-charts/ng2-charts';
import { FileUploadModule } from 'ng2-file-upload/ng2-file-upload';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';


import { SharedModule } from 'app/shared/shared.module';

import { NewvehicleComponent } from './newvehicle/newvehicle.component';
import { VehicleRoutes } from './vehicle.routing';
import { VehicledetailsComponent } from './vehicledetails/vehicledetails.component';
import { MakedialogueComponent } from './vehicledetails/makedialogue/makedialogue.component';
import { ModeldialogueComponent } from './vehicledetails/modeldialogue/modeldialogue.component';
import { PartsdialogueComponent } from './vehicledetails/partsdialogue/partsdialogue.component';
import { CarimgpopupComponent } from './newvehicle/carimgpopup/carimgpopup.component';
import { PartimgpopupComponent } from './newvehicle/partimgpopup/partimgpopup.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatListModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    MatMenuModule,
    MatSlideToggleModule,
    MatGridListModule,
    MatChipsModule,
    MatCheckboxModule,
    MatRadioModule,
    MatDialogModule,
    MatTabsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatProgressBarModule,
    FlexLayoutModule,
    NgxDatatableModule,
    ChartsModule,
    FileUploadModule,
    CommonModule,
    MatInputModule,
    NgxDatatableModule,
    MatDatepickerModule, 
    MatNativeDateModule,
    ReactiveFormsModule,
    MatSidenavModule,
    MatStepperModule,
    PerfectScrollbarModule,
    MatTooltipModule,
    MatDialogModule,
    MatExpansionModule,
    MatSnackBarModule,
    MatAutocompleteModule,
    SharedModule,
    RouterModule.forChild(VehicleRoutes)
  ],
  declarations: [
   
   NewvehicleComponent,
   
   VehicledetailsComponent,
   
   CarimgpopupComponent,
   
   PartimgpopupComponent
  ],
  exports:[
    NewvehicleComponent,
   
    VehicledetailsComponent,
    
    CarimgpopupComponent,
    
    PartimgpopupComponent
  ]
  , entryComponents: [CarimgpopupComponent,
    
    PartimgpopupComponent]
})
export class VehicleModule { }
